import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { navigate } from 'gatsby'
import LoadingImage from "../images/MineralSwimV2_HomePage_BCKWITHICONS.jpeg"


const S = () => {
  useEffect(() => {
    const newPath = `/${window.location.hash}`;
    if (window.location.hash) {		
			navigate(newPath, { replace: true });
    }
		if (window.location.search) {
			window.location.search.split("=").length>2? navigate(`/benefits/${window.location.search.split("=")[2]}/#main-content` , { replace: true }) : navigate( `/${window.location.search.split("=")[1]}/#main` , { replace: true })
		}
		else {
		}
  }, []);

	return (
		<Layout className="BlogPage">
			<Helmet>
				<title>Mineral Swim</title>
				<meta
					name="description"
					content="Explore frequently asked questions on mineral pool, magnesium pool and pool maintenance in general."
				/>
				<meta name="og:site_name" content="s" />
				<meta name="keywords" content="s" />
				<link rel="canonical" href="https://mineralswim.com/redirect/" />
			</Helmet>
			<main>
				<div className="z-20 flex justify-center items-center text-5x mb-20" style={{height:"88vh", background:`url(${LoadingImage})`, backgroundSize: "cover",
  backgroundPosition: "center",
	filter: 'blur(10px)' }}>	
				<div>
				Loading...
				</div>
				</div>
			</main>
		</Layout>
	)
}


export default S
